import React, { useState } from 'react'

import { PlayButton } from 'components/play-button/PlayButton'
import { Container } from 'components/layout/Container'
import { Segment } from 'components/layout/Segment'

import s from './Embed.scss'

interface IProps {
  embedUrl: string
  className?: string
  type?: 'inline' | 'block'
}

export const EmbedStream = ({ className, type, embedUrl }: IProps) => {
  const src = embedUrl

  if (!src) {
    return null
  }

  const content = (
    <div className={s('embed', 'block', className)}>
      <div className={s.embed__wrapper}>
        <iframe
          width='1290'
          height='726'
          seamless
          className={s.embed__iframe}
          frameBorder='0'
          allowFullScreen
          src={src}
        />
      </div>
    </div>
  )

  if (type === 'inline') {
    return content
  }

  return (
    <Segment>
      <Container>{content}</Container>
    </Segment>
  )
}
