import React from 'react';
import createLink from 'utils/createLink';
import { get, isEmpty } from 'lodash';

import RichText from 'components/rich-text/RichText';

import { Segment } from 'components/layout/Segment';
import { CtaCarousel } from 'components/cta-carousel/CtaCarousel';
import { CtaItem } from 'components/cta-carousel/CtaItem';
import { Image } from 'components/image/Image';
import { PrismicImage } from 'components/prismic-image/PrismicImage';

interface IField {
  cta_carousel_image: Prismic.IImageBlur;
  cta_carousel_kicker: Prismic.IRichText;
  cta_carousel_kicker_image: Prismic.IImageBlur;
  cta_carousel_heading: Prismic.IRichText;
  cta_carousel_text: Prismic.IRichText;
  cta_carousel_link: Prismic.ILink;
}

interface IProps {
  fields: [IField];
}

export const CtaCarouselContainer = ({ fields }: IProps) => {

  const renderItem = (
    {
      cta_carousel_image,
      cta_carousel_kicker,
      cta_carousel_kicker_image,
      cta_carousel_heading,
      cta_carousel_text,
      cta_carousel_link,
    }: IField,
    i: number,
  ) => {
    if (isEmpty(cta_carousel_image)) { return; }
    const image = !isEmpty(cta_carousel_image) && !isEmpty(cta_carousel_image.x1) ?
      <PrismicImage image={cta_carousel_image} view="x1" /> : undefined;
    const heading = RichText.asText(cta_carousel_heading) || '';
    const kicker = !isEmpty(cta_carousel_kicker_image) ?
    (
      <Image
        src={get(cta_carousel_kicker_image, 'x1.url')}
        src2x={get(cta_carousel_kicker_image, 'x2.url')}
        alt={RichText.asText(cta_carousel_kicker) || ''}
      />
    )
      : RichText.asText(cta_carousel_kicker);

    return (
      <CtaItem
        key={`${heading}-${i}`}
        link={createLink(cta_carousel_link)}
        kicker={kicker}
        heading={heading}
        text={RichText.asText(cta_carousel_text)}
        image={image}
      />
    );
  };

  return (
    <Segment>
      {!isEmpty(fields) &&
        <CtaCarousel>
          {fields.map(renderItem)}
        </CtaCarousel>
      }
    </Segment>
  );
};
