/* layout is 2 columns, with an image in each, and an optional quote under the first image */

import React, { cloneElement } from 'react';

import { Container } from 'components/layout/Container';
import { GalleryButton } from 'components/gallery/GalleryButton';
import { Row } from 'components/layout/Row';
import { Segment } from 'components/layout/Segment';

import s from './DefaultIntro.scss';

interface IProps {
  contentA?: React.ReactElement<any>;
  contentB?: React.ReactElement<any>;
  image1?: React.ReactElement<any>;
  image1Caption: string;
  image1Author: string;
  image1Region?: string | undefined;
  image1Quote: string;
  image2?: React.ReactElement<any>;
  image2Caption: string;
  image2Author: string;
  image2Region: string | undefined;
  image2Quote: string;
  quote?: string;
}

export const DefaultIntro = ({
  contentA,
  image1,
  image1Caption,
  image1Author,
  image1Region,
  image1Quote,
  quote,
  contentB,
  image2,
  image2Caption,
  image2Author,
  image2Region,
  image2Quote,
}: IProps) => {
  const image1Url = image1 && image1.props.image.url || undefined;
  const image2Url = image2 && image2.props.image.url || undefined;

  const image1Info = {
    url: image1Url,
    region: image1Region,
    credits: image1Author,
    caption: image1Caption,
    quote: image1Quote,
  };

  const image2Info = {
    url: image2Url,
    region: image2Region,
    credits: image2Author,
    caption: image2Caption,
    quote: image2Quote,
  };

  return (
    <Segment>
      <div className={s.defaultIntro}>
        <Container>
          <Row>
            <div className={s('defaultIntro__col')}>
              <div className={s('defaultIntro__content', { hasImage: image1 })}>
                {contentA}
              </div>
              {image1 && (
                <div className={s.defaultIntro__imageWrap}>
                  <div className={s.defaultIntro__imageRatio}>
                    {cloneElement(image1, {
                      className: s.defaultIntro__image,
                    })}

                    <div className={s.defaultIntro__button}>
                      <GalleryButton imageInfo={image1Info} />
                    </div>
                  </div>
                </div>
              )}
              {quote && (
                <div className={s.defaultIntro__quoteWrap}>
                  <q className={s.defaultIntro__quote}>{quote}</q>
                </div>
              )}
            </div>
            <div className={s.defaultIntro__col}>
              {image2 && (
                <div className={s.defaultIntro__imageWrap}>
                  <div className={s.defaultIntro__imageRatio}>
                    {cloneElement(image2, {
                      className: s.defaultIntro__image,
                    })}
                    <div className={s.defaultIntro__button}>
                      <GalleryButton imageInfo={image2Info} />
                    </div>
                  </div>
                </div>
              )}
              <div className={s.defaultIntro__content}>
                {contentB}
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </Segment>
  );
};
