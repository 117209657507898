import React from 'react';
import { isEmpty, get } from 'lodash';
import RichText from 'components/rich-text/RichText';

import { NumberedListItem } from 'components/slices/NumberedListItem';

import { NumberedImage } from 'components/numbered-image/NumberedImage';
import { PrismicImage } from 'components/prismic-image/PrismicImage';
import { Map, TRegions } from 'components/map/Map';

interface IProps {
  list_available: Prismic.IRichText;
  list_image: Prismic.IImageGallery;
  list_image_author: Prismic.IRichText;
  list_image_caption: Prismic.IRichText;
  list_image_number: number;
  list_map: string;
  list_text: Prismic.IRichText;
  list_title: Prismic.IRichText;
}

export const LargeListItem = ({
  list_available,
  list_image,
  list_image_author,
  list_image_caption,
  list_image_number,
  list_map,
  list_text,
  list_title,
}: IProps) => {

  const imageNumber = list_image_number || 0;
  const heading = !isEmpty(list_title) ? RichText.asText(list_title) : null;
  const caption = RichText.asText(list_image_caption) || get(list_image, 'alt', '');
  const author = RichText.asText(list_image_author) || get(list_image, 'copyright', '');
  const available = !isEmpty(list_available) ? RichText.asText(list_available) : null;
  const region = !isEmpty(list_map) ? list_map.toLowerCase() : 'all';

  // Elements
  const imageEl = !isEmpty(list_image) && !isEmpty(list_image.x1) ?
    <PrismicImage image={list_image} view="x1" /> : undefined;
  const numberedImage = !isEmpty(list_image) && !isEmpty(list_image.x1) ? (
    <NumberedImage imageNumber={imageNumber} image={imageEl} description={caption} credits={author} />
  ) : null;
  const map = !isEmpty(list_map) ? <Map region={region as TRegions} size="small" /> : null;

  return (
    <NumberedListItem
      image={numberedImage}
      heading={heading}
      map={map}
      available={available}
      location={list_map}
    >
      {!isEmpty(list_text) &&
        <RichText>
          {RichText.render(list_text)}
        </RichText>
      }
    </NumberedListItem>
  );
};
