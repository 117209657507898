import React from 'react';

import { Container } from 'components/layout/Container';
import { Row } from 'components/layout/Row';
import { Figure } from 'components/figure/Figure';

import s from './NumberedImage.scss';

interface IProps {
  image?: React.ReactElement<any>;
  imageNumber: number;
  credits?: string;
  description?: string;
}

export const NumberedImage = ({ image, description, credits, imageNumber }: IProps) => {

  let formattedNumber;

  const align = imageNumber % 2 ? 'right' : 'left';

  if (imageNumber) {
    formattedNumber = imageNumber < 10 ? `0${imageNumber}` : imageNumber;
  }

  if (!image) { return null; }

  return (
    <div className={s('numberedImage', align )}>
      <Container>
        <div className={s.numberedImage__imageWrap}>
          <div className={s.numberedImage__imageInner}>
            <Figure
              className={s.numberedImage__figure}
              image={image}
              description={description}
              credits={credits}
              captionPosition="inside"
              captionAlignment="right"
            />
          </div>
        </div>
      </Container>
      <div className={s.numberedImage__numberWrap}>
        <Container>
          <Row>
            <span className={s.numberedImage__number}>{formattedNumber}</span>
          </Row>
        </Container>
      </div>
    </div>
  );
};

NumberedImage.defaultProps = {
  align: 'left',
};
