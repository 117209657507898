import React from 'react'
import s from './Stream.scss'
import { Container } from 'components/layout/Container'
import { EmbedStream } from 'components/slices/EmbedStream'
interface IProps {
  title: string
  description: string
}

export const Stream = ({ title, description }: IProps) => (
  <>
    <EmbedStream embedUrl='https://soundslikeyouneediceland.com/volcano' />
    <Container>
      <div className={s.stream__container}>
        <div className={s.stream}>
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
      </div>
    </Container>
  </>
)
