import React from 'react';
import { isEmpty } from 'lodash';

import RichText from 'components/rich-text/RichText';
import { PrismicImage } from 'components/prismic-image/PrismicImage';
import { VideoFullscreen } from 'components/slices/VideoFullscreen';

interface IProps {
  fs_title: Prismic.IRichText;
  fs_kicker: Prismic.IRichText;
  fs_image_caption: Prismic.IRichText;
  fs_image_author: Prismic.IRichText;
  fs_youtube_id: Prismic.IRichText;
  fs_video: any;
  fs_image: any;
}

export const VideoFullscreenContainer = ({
  fs_title,
  fs_kicker,
  fs_image,
  fs_image_caption,
  fs_image_author,
  fs_video,
  fs_youtube_id, // modal video
  ...props
 }: IProps) => {

   const bgImage = !isEmpty(fs_image) && !isEmpty(fs_image.hero) ?
     <PrismicImage image={fs_image} view="hero" /> : undefined;

   return (
     <VideoFullscreen
      imageCaption={RichText.asText(fs_image_caption, '')}
      imageCredits={RichText.asText(fs_image_author, '')}
      heading={RichText.asText(fs_title, '')}
      kicker={RichText.asText(fs_kicker, '')}
      video={fs_video.url}
      youtubeID={RichText.asText(fs_youtube_id, '')}
      image={bgImage}
      poster={fs_image.url}
     />
   );
 };
