import React from 'react';
import { isEmpty, get } from 'lodash';

import RichText from 'components/rich-text/RichText';
import { ImageGroup } from 'components/image-group/ImageGroup';
import { Figure } from 'components/figure/Figure';
import { Segment } from 'components/layout/Segment';
import { PrismicImage } from 'components/prismic-image/PrismicImage';

interface IProps {
  grid_large_image: Prismic.IImageGallery;
  grid_large_caption: Prismic.IRichText;
  grid_large_author: Prismic.IRichText;
  grid_large_region: any;
  grid_large_quote: Prismic.IRichText;
  grid_image_position: string;
  grid_medium_image: Prismic.IImageGallery;
  grid_medium_caption: Prismic.IRichText;
  grid_medium_author: Prismic.IRichText;
  grid_medium_region: any;
  grid_medium_quote: Prismic.IRichText;
  grid_small_image: Prismic.IImageGallery;
  grid_small_caption: Prismic.IRichText;
  grid_small_author: Prismic.IRichText;
  grid_small_region: any;
  grid_small_quote: Prismic.IRichText;
  grid_pair_alignment: string;
}

export const ImageGrid = ({
    grid_large_image,
    grid_large_caption,
    grid_large_author,
    grid_large_region,
    grid_large_quote,
    grid_image_position,
    grid_medium_image,
    grid_medium_caption,
    grid_medium_author,
    grid_medium_region,
    grid_medium_quote,
    grid_small_image,
    grid_small_caption,
    grid_small_author,
    grid_small_region,
    grid_small_quote,
    grid_pair_alignment,
  }: IProps) => {

  const largeImage = !isEmpty(grid_large_image) && !isEmpty(grid_large_image.x1) ?
    <PrismicImage image={grid_large_image} view="x1" /> : undefined;
  const largeCaption = RichText.asText(grid_large_caption) || get(grid_large_image, 'alt', '');
  const largeAuthor = RichText.asText(grid_large_author) || get(grid_large_image, 'copyright', '');
  const largeRegion = get(grid_large_region, '_meta.uid', undefined);
  const largeQuote = RichText.asText(grid_large_quote) || undefined;

  const mediumImage = !isEmpty(grid_medium_image) && !isEmpty(grid_medium_image.x1)  ?
    <PrismicImage image={grid_medium_image} view="x1" /> : undefined;
  const mediumCaption = RichText.asText(grid_medium_caption) || get(grid_medium_image, 'alt', '');
  const mediumAuthor = RichText.asText(grid_medium_author) || get(grid_medium_image, 'copyright', '');
  const mediumRegion = get(grid_medium_region, '_meta.uid', undefined);
  const mediumQuote = RichText.asText(grid_medium_quote) || undefined;

  const smallImage = !isEmpty(grid_small_image) && !isEmpty(grid_small_image.x1) ?
    <PrismicImage image={grid_small_image} view="x1" /> : undefined;
  const smallCaption = RichText.asText(grid_small_caption) || get(grid_small_image, 'alt', '');
  const smallAuthor = RichText.asText(grid_small_author) || get(grid_small_image, 'copyright', '');
  const smallRegion = get(grid_small_region, '_meta.uid', undefined);
  const smallQuote = RichText.asText(grid_small_quote) || undefined;

  const pairAlignment = !isEmpty(grid_pair_alignment) ? grid_pair_alignment.toLowerCase() : 'left';
  const largePosition = !isEmpty(grid_image_position) ? grid_image_position.toLowerCase() : 'bottom';

  if (!largeImage && !mediumImage && !smallImage) { return null; }

  return (
    <Segment>
      <ImageGroup
        pairAlignment={pairAlignment as 'left' | 'right'}
        largePosition={largePosition as 'bottom' | 'top'}
        large={
          <Figure
            image={largeImage}
            description={largeCaption}
            credits={!isEmpty(largeAuthor) ? largeAuthor : null}
            region={largeRegion}
            quote={largeQuote}
          />
        }
        medium={mediumImage ? (
          <Figure
            image={mediumImage}
            description={mediumCaption}
            credits={!isEmpty(mediumAuthor) ? mediumAuthor : null}
            region={mediumRegion}
            quote={mediumQuote}
          />
        ) : undefined}
        small={smallImage ? (
          <Figure
            image={smallImage}
            description={smallCaption}
            credits={!isEmpty(smallAuthor) ? smallAuthor : null}
            region={smallRegion}
            quote={smallQuote}
          />
        ) : undefined}
      />
    </Segment>
  );
};
