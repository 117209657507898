import React from 'react'

import { TextContainer } from 'containers/slices/Text'
import { EmbedContainer } from 'containers/slices/Embed'
import { EmbedStreamContainer } from 'containers/slices/EmbedStream'
import { LeadContainer } from 'containers/slices/Lead'
import { LargeListItem } from 'containers/slices/LargeListItem'
import { ImageGrid } from 'containers/slices/ImageGrid'
import { IntroContainer } from 'containers/slices/Intro'
import { ArticleCtaContainer } from 'containers/slices/ArticleCta'
import { CtaButtonContainer } from 'containers/slices/CtaButton'
import { VideoFullscreenContainer } from 'containers/slices/VideoFullscreen'
import { DefaultIntroContainer } from 'containers/slices/DefaultIntro'
import { MapHeadingContainer } from 'containers/slices/MapHeading'
import { MapListContainer } from 'containers/slices/MapList'
import { CtaCarouselContainer } from 'containers/slices/CtaCarousel'
import { Calendar } from 'containers/slices/Calendar'
import { PartnersContainer } from 'containers/slices/Partners'
import { Container } from '../../components/layout/Container'
import styles from '../../components/stream/Stream.scss'

interface IProps {
  data: any
}

export const Slices = ({ data }: IProps) => {
  return (
    <>
      {data.map((s: any, i: number) => {
        const key = `slice-${s.type}-${i}`

        switch (s.type) {
          case 'rich_text':
            return <TextContainer key={key} {...s.primary} />
          case 'rich_text_stream':
            return (
              <Container>
                <div className={styles.stream__container}>
                  <div className={styles.stream}>
                    <TextContainer key={key} {...s.primary} />
                  </div>
                </div>
              </Container>
            )
          case 'embed_video':
            return <EmbedContainer key={key} {...s.primary} />
          case 'embed':
            return (
              <EmbedStreamContainer
                key={key}
                description={s.description}
                title={s.title}
              />
            )
          case 'two_col_heading_text':
            return <LeadContainer key={key} {...s.primary} />
          case 'large_list_item':
            return <LargeListItem key={key} {...s.primary} />
          case 'photo_grid':
            return <ImageGrid key={key} {...s.primary} />
          case 'intro':
            return <IntroContainer key={key} {...s.primary} />
          case 'article_cta':
            return <ArticleCtaContainer key={key} {...s.primary} />
          case 'article_cta_button':
            return <CtaButtonContainer key={key} {...s.primary} />
          case 'fullscreen_video':
            return <VideoFullscreenContainer key={key} {...s.primary} />
          case 'two_col_copy':
            return <DefaultIntroContainer key={key} {...s.primary} />
          case 'subheader_with_map':
            return (
              <MapHeadingContainer key={key} fields={s.fields} {...s.primary} />
            )
          case 'map_list':
            return (
              <MapListContainer key={key} fields={s.fields} {...s.primary} />
            )
          case 'cta_carousel':
            return <CtaCarouselContainer key={key} fields={s.fields} />
          case 'calendar':
            return <Calendar key={key} fields={s.fields} {...s.primary} />
          case 'partners':
            return (
              <PartnersContainer key={key} fields={s.fields} {...s.primary} />
            )
          default:
            return null
        }
      })}
    </>
  )
}
