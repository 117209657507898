import React from 'react';

import { FormattedMessage } from 'react-intl';

import { Container } from 'components/layout/Container';
import { Row } from 'components/layout/Row';
import { Segment } from 'components/layout/Segment';

import s from './NumberedListItem.scss';

interface IProps {
  children: React.ReactNode;
  image: React.ReactElement<any> | null;
  map: React.ReactElement<any> | null;
  heading: string;
  location: string;
  available?: string;
}

export const NumberedListItem = ({ children, image, heading, map, available, location }: IProps) => {
  if (!children) { return null; }

  return (
    <Segment>
      <div className={s.numberedListItem}>
        {image}
        <Container>
          <div className={s.numberedListItem__content}>
            <Row>
              <div className={s.numberedListItem__overview}>
                <h2 className={s.numberedListItem__heading}>{heading}</h2>

                <Row>
                  <div className={s.numberedListItem__map}>{map}</div>
                  <dl className={s.numberedListItem__dl}>
                    <div>
                      {available && (
                        <>
                          <dt className={s.numberedListItem__dt}>
                            <FormattedMessage id="available" />
                          </dt>
                        <dd className={s.numberedListItem__dd}>{available}</dd>
                        </>
                      )}
                    </div>
                    <div>
                      <dt className={s.numberedListItem__dt}><FormattedMessage id="location" /></dt>
                      <dd className={s.numberedListItem__dd}>{location}</dd>
                    </div>
                  </dl>
                </Row>

              </div>
              <div className={s.numberedListItem__text}>
                {children}
              </div>
            </Row>
          </div>
        </Container>
      </div>
    </Segment>
  );
};
