import React from 'react';
import { isEmpty } from 'lodash';

import RichText from 'components/rich-text/RichText';
import { Intro } from 'components/intro/Intro';
import { Segment } from 'components/layout/Segment';

interface IProps {
  intro_text: any;
  intro_separator: string;
}

export const IntroContainer = ({ intro_text, intro_separator }: IProps) => {
  if (isEmpty(intro_text)) { return null; }

  const separator = !isEmpty(intro_separator) && intro_separator !== 'False';

  return (
    <Segment>
      <Intro separator={separator}>
        {RichText.render(intro_text)}
      </Intro>
    </Segment>
  );
};
