import React from 'react';
import { isEmpty } from 'lodash';
import createLink from 'utils/createLink';

import RichText from 'components/rich-text/RichText';
import { Carousel } from 'components/carousel/Carousel';
import { PrismicImage } from 'components/prismic-image/PrismicImage';
import { Segment } from 'components/layout/Segment';
import { UtilityCard } from 'components/utility-card/UtilityCard';

interface ICalendarLink {
  calendar_link: {
    title: Prismic.IRichText;
    location: Prismic.IRichText;
    link: Prismic.ILink;
    image: Prismic.IImageBlur;
    _meta: {
      uid: string;
    };
  };
}

interface IProps {
  fields: [ICalendarLink];
  calendar_month: Prismic.IRichText;
  calendar_year: Prismic.IRichText;
}

export const Calendar = ({ fields, calendar_month, calendar_year}: IProps) => {

  const renderItem = ({ calendar_link }: ICalendarLink, i: number) => {
    if (isEmpty(calendar_link) || isEmpty(calendar_link.image)) { return null; }

    const image = !isEmpty(calendar_link.image) && !isEmpty(calendar_link.image.x1) ?
      <PrismicImage image={calendar_link.image} view="x1" /> : undefined;

    return (
      <UtilityCard
        key={calendar_link._meta.uid || i}
        heading={RichText.asText(calendar_link.title)}
        category={RichText.asText(calendar_link.location)}
        link={createLink(calendar_link.link)}
      >
        {image}
      </UtilityCard>
    );
  };

  if (isEmpty(fields)) { return null; }

  return (
    <Segment>
      <Carousel
        slideCount={3}
        rowHeading={<span>{RichText.asText(calendar_month)} <br /> {RichText.asText(calendar_year)}</span>}
      >
        {fields.map(renderItem)}
      </Carousel>
    </Segment>
  );
};
