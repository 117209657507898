import React, { Children, cloneElement } from 'react';

import { Container } from 'components/layout/Container';
import { Row } from 'components/layout/Row';
import { Segment } from 'components/layout/Segment';

import s from './MapList.scss';

interface IProps {
  children: React.ReactNode;
  heading: string;
  map: React.ReactElement<any> | null;
}

export const MapList = ({ heading, children, map }: IProps) => {

  return (
    <Segment>
      <div className={s('mapList')}>
        <Container>
          <Row>
            <div className={s.mapList__content}>
              <h2 className={s.mapList__heading}>{heading}</h2>
              <ul className={s.mapList__ul}>
                {Children.map(children, (child, i) => <li className={s.mapList__li} key={`${s.mapList}-${i}`}>{child}</li>)}
              </ul>
            </div>
            {map && (
              <div className={s.mapList__map}>
                <div className={s.mapList__mapInner}>
                  {map}
                </div>
              </div>
            )}
          </Row>
        </Container>
      </div>
    </Segment>
  );
};

interface ItemProps {
  heading: string;
  image: React.ReactElement<any> | null;
  subheading: string;
}

export const MapListItem = ({ heading, image, subheading }: ItemProps) => {

  return (
      <div className={s('mapList__item')}>
        {image && cloneElement(image as React.ReactElement<any>, {
          className: s.mapList__image,
        })}
        <div className={s.mapList__itemText}>
          <h3 className={s.mapList__itemHeading}>{heading}</h3>
          <div className={s.mapList__subheading}>{subheading}</div>
        </div>
      </div>
  );
};
