import React from 'react';

import { Row } from 'components/layout/Row';
import { Segment } from 'components/layout/Segment';

import s from './Text.scss';

interface IProps {
  children: React.ReactNode;
}

export const Text = ({ children }: IProps) => {
  if (!children) { return null; }

  return (
    <Segment container>
      <Row>
        <div className={s.text}>
          {children}
        </div>
      </Row>
    </Segment>
  );
};
