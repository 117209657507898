import React from 'react';
import { isEmpty, get } from 'lodash';

import { PrismicImage } from 'components/prismic-image/PrismicImage';
import RichText from 'components/rich-text/RichText';

import { DefaultIntro } from 'components/slices/DefaultIntro';

interface IProps {
  copy_col_1_text: Prismic.IRichText;
  copy_col_1_image: Prismic.IImageGallery;
  copy_col_1_image_caption: Prismic.IRichText;
  copy_col_1_image_author: Prismic.IRichText;
  copy_col_1_image_region: any;
  copy_col_1_image_quote: Prismic.IRichText;
  copy_col_1_quote: Prismic.IRichText;
  copy_col_2_text: Prismic.IRichText;
  copy_col_2_image: Prismic.IImageGallery;
  copy_col_2_image_caption: Prismic.IRichText;
  copy_col_2_image_author: Prismic.IRichText;
  copy_col_2_image_region: any;
  copy_col_2_image_quote: Prismic.IRichText;
}

export const DefaultIntroContainer = ({
    copy_col_1_text,
    copy_col_1_image,
    copy_col_1_image_caption,
    copy_col_1_image_author,
    copy_col_1_image_region,
    copy_col_1_image_quote,
    copy_col_1_quote,
    copy_col_2_text,
    copy_col_2_image,
    copy_col_2_image_caption,
    copy_col_2_image_author,
    copy_col_2_image_region,
    copy_col_2_image_quote,
  }: IProps) => {

  const col1Image = !isEmpty(copy_col_1_image) && !isEmpty(copy_col_1_image.x1) ?
    <PrismicImage image={copy_col_1_image} view="x1" /> : undefined;
  const col2Image = !isEmpty(copy_col_2_image) && !isEmpty(copy_col_2_image.x1) ?
    <PrismicImage image={copy_col_2_image} view="x1" /> : undefined;

  return (
    <DefaultIntro
      contentA={!isEmpty(copy_col_1_text) ? <RichText>{RichText.render(copy_col_1_text)}</RichText> : undefined}
      contentB={!isEmpty(copy_col_1_text) ? <RichText>{RichText.render(copy_col_2_text)}</RichText> : undefined}
      quote={!isEmpty(copy_col_1_quote) ? RichText.asText(copy_col_1_quote) : undefined}
      image1={col1Image}
      image1Caption={RichText.asText(copy_col_1_image_caption) ||
        get(copy_col_1_image, 'alt', undefined)
      }
      image1Author={RichText.asText(copy_col_1_image_author) ||
        get(copy_col_1_image, 'copyright', undefined)
      }
      image1Region={get(copy_col_1_image_region, '_meta.uid', undefined)}
      image1Quote={RichText.asText(copy_col_1_image_quote)}
      image2={col2Image}
      image2Caption={RichText.asText(copy_col_2_image_caption) ||
        get(copy_col_2_image, 'alt', undefined)
      }
      image2Author={RichText.asText(copy_col_2_image_author) ||
        get(copy_col_2_image, 'copyright', undefined)
      }
      image2Region={get(copy_col_2_image_region, '_meta.uid', undefined)}
      image2Quote={RichText.asText(copy_col_2_image_quote)}
    />
  );
};
