import fallbackImage from 'assets/images/share.jpg';
import { isEmpty, truncate } from 'lodash';
import React from 'react';
import Helmet from 'react-helmet';

interface IProps {
  title: string;
  description: string;
  image: string;
  imageAlt: string;
}

const Meta = ({ title, description, image, imageAlt }: IProps) => {
  const meta = [
    { property: 'og:title', content: title },
    { name: 'twitter:title', content: title },
    { name: 'twitter:card', content: 'summary' },
    { name: 'twitter:site', content: '@iceland' },
    {
      name: 'google-site-verification',
      content: 'FefkVMq-Gl086IBhhpak0nVWd43VrJom82rwcd6e3DY',
    },

    {
      name: 'facebook-domain-verification',
      content: 'maq70ddqhx4iarft45zm98kyghu7zr',
    },
  ];
  const shareImage = !isEmpty(image) ? image : fallbackImage;

  if (description) {
    const truncatedDescription = truncate(description.replace(/\n+/g, ' '), {
      length: 300,
      separator: '. ',
    });

    meta.push(
      { name: 'description', content: truncatedDescription },
      { property: 'og:description', content: truncatedDescription },
      { name: 'twitter:description', content: truncatedDescription },
    );
  }

  if (shareImage) {
    meta.push(
      { property: 'og:image', content: shareImage },
      { property: 'og:image:width', content: '1200px' },
      { property: 'og:image:height', content: '630px' },
      { property: 'og:image:alt', content: imageAlt },
      { name: 'twitter:image', content: shareImage },
    );
  }

  return <Helmet title={title} meta={meta} />;
};

export default Meta;
