import React from 'react';
import { isEmpty } from 'lodash';
import createLink from 'utils/createLink';

import RichText from 'components/rich-text/RichText';
import { Segment } from 'components/layout/Segment';
import { CtaButton } from 'components/cta-button/CtaButton';
import { FormattedMessage } from 'react-intl';

interface IProps {
  cta_button_link: Prismic.ILink;
  cta_button_text: Prismic.IRichText;
  cta_button_external_link: any;
  cta_button_show_arrow: string;
}

export const CtaButtonContainer = ({ cta_button_link, cta_button_text, cta_button_external_link, cta_button_show_arrow }: IProps) => {

  const showArrow = !isEmpty(cta_button_show_arrow) && cta_button_show_arrow !== 'False';

  return (
    <Segment container>
      <FormattedMessage id="view-list">
        {(defaultLinkText) => {
          const linkText = cta_button_text ? (RichText.asText(cta_button_text)) : (defaultLinkText as string);
          const link = cta_button_link ? createLink(cta_button_link) : (cta_button_external_link && cta_button_external_link.url);

          return (
            <CtaButton
              to={link}
              arrow={showArrow}
            >{linkText}
            </CtaButton>
          );
        }}
      </FormattedMessage>
    </Segment>
  );
};
