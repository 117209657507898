import React from 'react'
import { isEmpty } from 'lodash'
import { PrismicImage } from 'components/prismic-image/PrismicImage'
import { Embed } from 'components/slices/Embed'

interface IProps {
  embed: {
    html: string
    thumbnail_url: string
    duration: number
    title: string
    provider_name: string
    video_id: number
    key: string
    embed: any
    embed_url: string
  }
  embed_placeholder: Prismic.IImageBlur
  type?: 'inline' | 'block'
  className?: string
}

export const EmbedContainer = ({
  embed,
  embed_placeholder,
  type,
  className,
}: IProps) => {
  if (isEmpty(embed)) {
    return null
  }

  const {
    provider_name: providerName,
    duration,
    html,
    title,
    video_id: videoId,
    thumbnail_url: thumbnailUrl,
    embed_url: embedUrl,
  } = embed

  if (providerName !== 'Vimeo' && providerName !== 'YouTube') {
    return null
  }

  const image =
    !isEmpty(embed_placeholder) && !isEmpty(embed_placeholder.x1) ? (
      <PrismicImage image={embed_placeholder} view='x1' />
    ) : (
      thumbnailUrl
    )

  let time = null
  if (duration) {
    const minutes = Math.floor(duration / 60)
    const seconds = duration % 60
    time = `${minutes}:${seconds}`
  }

  return (
    <Embed
      html={html}
      provider={providerName}
      embedUrl={embedUrl}
      image={image}
      duration={time}
      caption={title}
      id={videoId}
      type={type}
      className={className}
    />
  )
}
