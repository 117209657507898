import React from 'react'
import { Stream } from 'components/stream/Stream'

interface IProps {
  description: string
  title: string
}

export const EmbedStreamContainer = ({ description, title }: IProps) => {
  return (
    <div>
      <Stream title={title} description={description} />
    </div>
  )
}
