import React from 'react';

import { Row } from 'components/layout/Row';
import { Segment } from 'components/layout/Segment';

import s from './Lead.scss';

interface IProps {
  heading: string;
  children: React.ReactNode;
}

export const Lead = ({ heading, children }: IProps) => {
  if (!heading || !children) { return null; }

  return (
    <Segment container>
      <Row>
        <div className={s.lead__headingWrap}>
          <h2 className={s.lead__heading}>{heading}</h2>
        </div>
        <div className={s.lead__content}>
          {children}
        </div>
      </Row>
    </Segment>
  );
};
