import React from 'react';
import { isEmpty } from 'lodash';
import createLink from 'utils/createLink';

import RichText from 'components/rich-text/RichText';
import { Segment } from 'components/layout/Segment';
import { PrismicImage } from 'components/prismic-image/PrismicImage';

import { ArticleCta } from 'components/article-cta/ArticleCta';
import { FormattedMessage } from 'react-intl';

interface IProps {
  cta_image: Prismic.IImageBlur;
  cta_link: Prismic.ILink;
  cta_title: Prismic.IRichText;
  link_text: Prismic.IRichText;
  external_link: any;
}

export const ArticleCtaContainer = ({ cta_image, cta_link, cta_title, link_text, external_link }: IProps) => {

  const image = !isEmpty(cta_image) && !isEmpty(cta_image.x1) ?
    <PrismicImage image={cta_image} view="x1" /> : undefined;

  return (
    <Segment>
      <FormattedMessage id="view-list">
        {(defaultLinkText) => {
          const linkText = link_text ? (RichText.asText(link_text)) : (defaultLinkText as string);
          const link = cta_link ? createLink(cta_link) : (external_link && external_link.url);

          return (<ArticleCta
            heading={RichText.asText(cta_title)}
            link={link}
            image={image}
            linkText={linkText}
          />);
        }}
      </FormattedMessage>
    </Segment>
  );
};
