import React from 'react';
import { isEmpty } from 'lodash';

import RichText from 'components/rich-text/RichText';

import { Lead } from 'components/slices/Lead';

interface IProps {
  heading: Prismic.IRichText;
  text: Prismic.IRichText;
}

export const LeadContainer = ({ heading, text }: IProps) => {
  if (isEmpty(heading) || isEmpty(text)) { return null; }

  return (
    <Lead heading={RichText.asText(heading)}>
      <RichText>
        {RichText.render(text)}
      </RichText>
    </Lead>
  );
};
