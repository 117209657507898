import React from 'react';

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import RichText from 'components/rich-text/RichText';
import { Map, TRegions } from 'components/map/Map';
import { MapHeading } from 'components/slices/MapHeading';
import { FormattedMessage } from 'react-intl';

interface IRegion {
  subheader_map_regions: {
    title: string;
    _meta: {
      uid: string;
    };
  };
}

interface IProps {
  subheader_title: Prismic.IRichText;
  subheader_map: string;
  subheader_available: Prismic.IRichText;
  show_maps_labels: boolean;
  fields: IRegion[];
}

export const MapHeadingContainer = ({
    subheader_title,
    subheader_map,
    show_maps_labels,
    subheader_available,
    fields,
  }: IProps) => {

  const region = !isEmpty(subheader_map) ? subheader_map.toLowerCase() : <FormattedMessage id="all-regions" />;
  const regions = Array.isArray(fields) ? fields.map((i: IRegion) => {
    const title = get(i, 'subheader_map_regions.title', null);
    const id = get(i, 'subheader_map_regions._meta.uid', null);

    if (title && id) {
      return { title, id };
    }

    return null;
  }).filter(Boolean) : [];

  const location = !isEmpty(subheader_map) ? subheader_map : undefined;
  const available = RichText.asText(subheader_available);

  // Changed in Prismic but requires re-save of all articles, so fix here
  const locationToLower = location && location.toLowerCase()
  const displayedRegion = locationToLower === 'all' ? <FormattedMessage id="all-regions" /> : <FormattedMessage id={locationToLower} />;

  const showLabels = show_maps_labels || show_maps_labels === null ? true : false;

  const displayedLocation = regions.length > 0 ? regions.map((i: any) => i.title).join(', ') : displayedRegion;

  return (
    <MapHeading
      heading={
        !isEmpty(subheader_title) ? RichText.asText(subheader_title) : undefined
      }
      map={
        !isEmpty(subheader_map) ? (
          <Map
            region={regions.length === 0 ? (region as TRegions) : undefined}
            regions={regions.length > 0 ? regions : undefined}
            size="medium"
          />
        ) : (
          undefined
        )
      }
      available={!isEmpty(available) && showLabels ? available : undefined}
      location={showLabels ? displayedLocation : undefined}
    />
  );
};
