import React from 'react';
import { isEmpty } from 'lodash';

import RichText from 'components/rich-text/RichText';
import Mapbox from 'components/mapbox/Mapbox';
import { MapList, MapListItem } from 'components/slices/MapList';
import { PrismicImage } from 'components/prismic-image/PrismicImage';

interface IProps {
  fields: [IMapItem];
  map_title: Prismic.IRichText;
  map_center: Prismic.IGeo;
  map_zoom: number;
}

interface IMapItem {
  map_item_geo_location: Prismic.IGeo;
  map_item_image: Prismic.IImageGallery;
  map_item_subtitle: Prismic.IRichText;
  map_item_title: Prismic.IRichText;
}

const MAP_WIDTH = 850;
const MAP_HEIGHT = 750;

export const MapListContainer = ({ fields, map_title, map_center, map_zoom }: IProps) => {

  const renderItem = ({ map_item_image, map_item_subtitle, map_item_title}: IMapItem, i: number) => {
    const image = !isEmpty(map_item_image) && !isEmpty(map_item_image.x1) ?
      <PrismicImage image={map_item_image} view="x1" /> : null ;
    const title = RichText.asText(map_item_title) || '';

    return (
      <MapListItem
        key={`${title}-${i}`}
        image={image}
        heading={title}
        subheading={RichText.asText(map_item_subtitle)}
      />
    );
  };

  const zoom = map_zoom !== null ? [map_zoom] : [];
  const geoLocations = !isEmpty(fields) ? fields.map((item: IMapItem) =>
    (item.map_item_geo_location)) : [];

  return (
    <MapList
      heading={!isEmpty(map_title) ? RichText.asText(map_title) : null}
      map={!isEmpty(geoLocations) ?
        (<Mapbox
          width={MAP_WIDTH}
          height={MAP_HEIGHT}
          markers={geoLocations}
          zoom={zoom}
          center={!isEmpty(map_center) ? [map_center.longitude, map_center.latitude] : []}
        />
      ) : null}
    >
      {!isEmpty(fields) &&
        fields.map(renderItem)
      }
    </MapList>
  );
};
