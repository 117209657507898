import React from 'react';
import { isEmpty } from 'lodash';

import { Text } from 'components/slices/Text';

import RichText from 'components/rich-text/RichText';

interface IProps {
  text: Prismic.IRichText;
}

export const TextContainer = ({ text }: IProps) => {
  if (isEmpty(text)) { return null; }

  return (
    <Text>
      <RichText>
        {RichText.render(text)}
      </RichText>
    </Text>
  );
};
