import React, { useState, cloneElement } from 'react'

import { Image } from 'components/image/Image'
import { PlayButton } from 'components/play-button/PlayButton'
import { Container } from 'components/layout/Container'
import { Segment } from 'components/layout/Segment'

import s from './Embed.scss'

interface IProps {
  html: string
  provider: string
  embedUrl: string
  image: React.ReactElement<any> | string
  duration?: string | null
  caption: string
  id: number
  className?: string
  type?: 'inline' | 'block'
}

function parse (url: string) {
  // - Supported YouTube URL formats:
  //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
  //   - http://youtu.be/My2FRPA3Gf8
  //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
  // - Supported Vimeo URL formats:
  //   - http://vimeo.com/25451551
  //   - http://player.vimeo.com/video/25451551
  // - Also supports relative URLs:
  //   - //player.vimeo.com/video/25451551

  if (!url) {
    return undefined
  }

  // tslint:disable-next-line max-line-length
  url.match(
    /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/,
  )

  let type

  if (RegExp.$3.indexOf('youtu') > -1) {
    type = 'youtube'
  } else if (RegExp.$3.indexOf('vimeo') > -1) {
    type = 'vimeo'
  }

  return {
    type,
    id: RegExp.$6,
  }
}

function getSource (provider: string, id: number, embedUrl: string) {
  if (provider === 'Vimeo') {
    return `https://player.vimeo.com/video/${id}?autoplay=1&title=0&byline=0&portrait=0&quality=720p`
  }

  if (provider === 'YouTube') {
    const parsed = parse(embedUrl)

    if (parsed) {
      return `https://www.youtube.com/embed/${parsed.id}?&autoplay=1`
    }
  }

  return null
}

export const Embed = ({
  id,
  duration,
  image,
  caption,
  className,
  type,
  provider,
  embedUrl,
}: IProps) => {
  const [active, setActive] = useState(false)

  const src = getSource(provider, id, embedUrl)

  if (!src) {
    return null
  }

  const content = (
    <div className={s('embed', type, className, { active })}>
      <div className={s.embed__wrapper}>
        {typeof image !== 'string' ? (
          cloneElement(image, {
            className: s.embed__image,
          })
        ) : (
          <Image className={s.embed__image} src={image} alt={caption || ''} />
        )}
        {active && (
          <iframe
            width='1290'
            height='726'
            seamless
            className={s.embed__iframe}
            frameBorder='0'
            allowFullScreen
            src={src}
          />
        )}

        {!active && (
          <div className={s.embed__controls}>
            <div className={s.embed__play}>
              <PlayButton
                onClick={() => {
                  setActive(true)
                }}
              />
            </div>
            {type !== 'inline' && (
              <div className={s.embed__text}>
                {caption && <span className={s.embed__caption}>{caption}</span>}
                {duration && (
                  <span className={s.embed__duration}>{duration}</span>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )

  if (type === 'inline') {
    return content
  }

  return (
    <Segment>
      <Container>{content}</Container>
    </Segment>
  )
}
