import React from 'react';
import { isEmpty } from 'lodash';
import { linkResolver } from 'utils/linkResolver';

import { Segment } from 'components/layout/Segment';
import RichText from 'components/rich-text/RichText';
import { Partners } from 'components/partners/Partners';
import { PartnersItem } from 'components/partners/PartnersItem';
import { Image } from 'components/image/Image';

interface IPartner {
  image: Prismic.IImageBlur;
  name: Prismic.IRichText;
  link: Prismic.ILink;
}

interface IProps {
  fields: [IPartner];
  partner_heading: Prismic.IRichText;
  partner_alignment: string;
}

export const PartnersContainer = ({ partner_heading, partner_alignment, fields }: IProps) => {
  if (isEmpty(fields)) { return null; }

  const align = isEmpty(partner_alignment) ? 'left' : partner_alignment;

  return (
    <Segment container>
      <Partners
        title={RichText.asText(partner_heading)}
        align={align}
      >
        {fields.map(({ partner }, i) => {
          if (isEmpty(partner)) { return null }

          const url = !isEmpty(partner.link) && !isEmpty(partner.link.url) ? partner.link.url : '';

          const image = !isEmpty(partner.image) && !isEmpty(partner.image.logo_x1) ?
            <Image
              src={partner.image.logo_x1.url}
              src2x={partner.image.logo_x2.url}
              width={partner.image.logo_x1.dimensions.width}
              height={partner.image.logo_x1.dimensions.height}
              loading="lazy"
            /> : null ;

          return (
            <PartnersItem link={url && url} name={RichText.asText(partner.name)} key={`partners-${i}`}>
              {image}
            </PartnersItem>
          )
        })}
      </Partners>
    </Segment>
  );
};
